import gql from 'graphql-tag'

export default gql`
  query Originals {
    originals(orderBy: { publishedAt: desc }, where: { active: { equals: true } }) {
      title
      description
      type
      image {
        url
      }
      imageAlt
      vimeoVideoId
    }
  }
`
