import gql from 'graphql-tag'

export default gql`
  query FeaturedOriginals {
    originals(orderBy: { publishedAt: desc }, where: { active: { equals: true }, featured: { equals: true } }) {
      title
      description
      type
      image {
        url
      }
      vimeoVideoId
    }
  }
`
