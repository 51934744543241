import gql from 'graphql-tag'

export default gql`
  query CalendlyUrl {
    setting(where: { key: "calendly_url" }) {
      key
      value
    }
  }
`
