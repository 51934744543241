import gql from 'graphql-tag'

export const getZohoAccessTokenQuery = `  query getZohoAccessToken {
    settings(where: { key: { equals: "zoho_access_token" } }) {
      id
      key
      value
    }
  }`

export default gql(getZohoAccessTokenQuery)
