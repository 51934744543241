import gql from 'graphql-tag'

export const getZohoRefreshTokenQuery = `  query getZohoRefreshToken {
    settings(where: { key: { equals: "zoho_refresh_token" } }) {
      id
      key
      value
    }
  }`

export default gql(getZohoRefreshTokenQuery)
