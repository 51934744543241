import gql from 'graphql-tag'

export default gql`
  query socials {
    socials {
      name
      url
    }
  }
`
