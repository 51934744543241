import gql from 'graphql-tag'

export default gql`
  query Blogs {
    blogs(orderBy: { publishedAt: desc }, where: { published: { equals: true } }) {
      title
      slug
      imageAlt
      image {
        url
      }
    }
  }
`
