import gql from 'graphql-tag'

export default gql`
  query ContactSettings {
    settings(where: { key: { in: ["address_line_1", "address_line_2"] } }) {
      key
      value
    }
  }
`
