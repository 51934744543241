import gql from 'graphql-tag'

export default gql`
  query Faqs {
    faqs {
      title
      content {
        document
      }
    }
  }
`
