import gql from 'graphql-tag'

export default gql`
  query teamMembers {
    teamMembers {
      name
      pronouns
      role
      image {
        url
      }
      altImage {
        url
      }
      imageAltText
    }
  }
`
